<template>
  <div class="mt-5">
    <b-container>
      <div>
        <h2 class="font-weight-bolder text-colorBlue">
          An Attractive Tourism Market Stemming
        </h2>
        <p class="text-colorText font-weight-bold" style="font-size: 1.2rem">
          From A wide variety of Seasons, Landscape, Historical sites, Hotel
          Industry etc. Mostly within few hours Travel time.
        </p>
        <p class="mb-3 mt-1">
          <span class="font-weight-bolder text-danger">NOTE:</span> The prices
          mentioned below are only for single day.
        </p>
      </div>
    </b-container>

    <b-container class="mt-5 mb-5">
      <div id="specific-div">
        <b-row class="mt-3">
          <b-col
            v-for="(tourism, index) in tourisms"
            :key="index"
            :id="'tourism-' + tourism.id"
            class="d-flex flex-column justify-content-between"
            md="6"
            lg="6"
            style="border-radius: 20px"
            :class="{ 'bg-colorGrey ': isHovered === index }"
            @mouseover="isHovered = index"
            @mouseleave="isHovered = null"
          >
            <div
              class="text-center pt-3"
              style="height: 400px; overflow: hidden"
            >
              <img class="rounded" :src="tourism.images[0].image" alt="image" />
            </div>
            <div>
              <div class="pt-2 text-center">
                <h2 class="text-colorBlue font-weight-bolder">
                  {{ tourism.name }}
                </h2>
                <p class="font-weight-bold text-colorBlue">
                  {{ tourism.intro }}
                </p>
              </div>
            </div>

            <div>
              <ol class="text-dark font-weight-bolder">
                <li
                  v-for="(trip, tripIndex) in getTripsForTourism(tourism.id)"
                  :key="trip.id"
                >
                  <div
                    class="d-flex justify-content-between align-items-between w-100"
                  >
                    <div style="width: 70%" class="px-2">
                      <p v-html="trip.tour_plans"></p>
                    </div>
                    <div
                      class="text-colorGreen font-weight-bolder"
                      style="width: 10%"
                    >
                      {{ trip.charges }}$
                    </div>
                    <div class="px-50" style="width: 20%">
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio
                          :id="`tourism_${index}-trip_${tripIndex}`"
                          v-model="selectedTrips[index]"
                          :aria-describedby="ariaDescribedby"
                          :name="`tourism_${index}`"
                          :value="trip"
                          >check to select</b-form-radio
                        >
                      </b-form-group>
                    </div>
                  </div>
                </li>
              </ol>
              <!-- <div class="mb-1 font-weight-bolder text-colorGreen">
                Selected:
                <span
                  class="font-weight-bold text-dark"
                  v-html="
                    selectedTrips[index] && selectedTrips[index].tour_plans
                  "
                >
                </span>
              </div> -->
            </div>

            <!-- <div class="text-center">
              <b-button
                block
                rounded
                :variant="isInCart(tourism) ? 'colorBlue' : 'colorGreen'"
                size="sm"
                class="font-weight-bolder mb-sm-1"
                @click="handleCartAction(tourism, selectedTrips[index])"
              >
                {{ isInCart(tourism) ? "View Cart" : "Add to Cart" }}
              </b-button>
            </div> -->
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
  },
  mixins: [util],
  data() {
    return {
      isHovered: null,
      tourisms: [],
      trips: [],
      selectedTrips: {}, // Object to store selected trips for each tourism item
    };
  },
  async mounted() {
    this.scrollToHash();
    try {
      const tourismRes = await this.getTouristattractionPublic({});
      if (tourismRes.status === 200) {
        this.tourisms = tourismRes.data;

        // Initialize selectedTrips object with null for each tourism item
        this.tourisms.forEach((tourism, index) => {
          this.$set(this.selectedTrips, index, null);
        });
      }

      const tripsRes = await this.getTouristtripPublic({});
      if (tripsRes.status === 200) {
        this.trips = tripsRes.data;
      }
    } catch (error) {
      this.displayError(error);
    }
    // this.populateDisplayImage();
  },
  computed: {
    ...mapState({
      cart: (state) => state.appData.cart,
    }),
  },
  methods: {
    ...mapActions({
      getTouristattractionPublic: "appData/getTouristattractionPublic",
      getTouristtripPublic: "appData/getTouristtripPublic",
      addToCart: "appData/addToCart",
      removeFromCart: "appData/removeFromCart",
    }),
    // Function to filter trips for a specific tourism item
    getTripsForTourism(tourismId) {
      return this.trips.filter((trip) => trip.tourist_attraction === tourismId);
    },
    addCart(tourism, selectedTrip) {
      const cartItemId = `${tourism.id}_${this.cartItemType.TOURISM}`;
      const isInCart = this.cart.some((cartItem) => cartItem.id === cartItemId);

      if (!isInCart && selectedTrip) {
        this.addToCart({
          id: cartItemId,
          name: tourism.name,
          tourismId: tourism.id,
          tripName: selectedTrip.tour_plans,
          itemId: selectedTrip.id,
          price: selectedTrip.charges,
          type: this.cartItemType.TOURISM,
          quantity: 1,
          numberOfDays: selectedTrip.days,
        });
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Item added to cart successfully",
              variant: "success",
              icon: "BellIcon",
            },
          },
          {
            position: "top-right",
          }
        );
      } else if (!selectedTrip) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Please select a trip to add to your cart",
              variant: "danger",
              icon: "BellIcon",
            },
          },
          {
            position: "top-right",
          }
        );
      }
    },
    handleCartAction(tourism, selectedTrip) {
      if (this.isInCart(tourism)) {
        this.$router.push({ name: "Cart" });
      } else {
        this.addCart(tourism, selectedTrip);
      }
    },
    isInCart(tourism) {
      const cartItemId = `${tourism.id}_${this.cartItemType.TOURISM}`;
      return this.cart.some((cartItem) => cartItem.id === cartItemId);
    },
    scrollToHash(retries = 10) {
      if (retries < 0) return;

      this.$nextTick(() => {
        const hash = this.$route.hash;
        if (hash) {
          // Remove the `#` and prefix with 'tourism-' to match HTML IDs
          const id = hash.slice(1);
          const selector = "tourism-" + id;
          const element = document.getElementById(selector);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          } else {
            setTimeout(() => this.scrollToHash(retries - 1), 100); // Adjust delay as needed
          }
        } else {
          // If there's no hash, scroll to the top
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      });
    },
    // Function to add item to the cart
    // Function to populate the display image
    // populateDisplayImage() {
    //   if (this.tourisms && this.tourisms.length > 0) {
    //     this.tourisms.forEach((tourism) => {
    //       if (tourism.images && tourism.images.length > 0) {
    //         tourism.displayImage = tourism.images[0];
    //       }
    //     });
    //   }
    // },
  },
  watch: {
    $route(to, from) {
      this.scrollToHash();
    },
  },
};
</script>

<style scoped>
.my-border {
  border-bottom: 8px solid #06bd46;
}
.hover-img {
  display: none;
}
.image-container-hover:hover .hover-img {
  display: block;
  margin: 0 auto;
}

.image-container-hover:hover .base-img {
  display: none;
}
</style>
